<template>
  <div class="login-page" @keydown="onKeyDown">
    <div class="login-body">
      <div class="login-block">
        <base-row justify="center">
          <BaseLogo style="font-size: 26px" />
        </base-row>
        <BaseSeparationRow />
        <base-row>
          <el-tabs v-model="form.loginType" :stretch="true" v-loading="loading">
            <el-tab-pane v-for="tab in tabs" :key="tab.name" :label="tab.label + '登录'" :name="tab.name">
              <el-form :model="form" :rules="rules" :ref="'register-form-' + tab.name">
                <el-form-item :label="tab.label" :prop="tab.name">
                  <el-input v-model="form[tab.name]" />
                </el-form-item>
                <el-form-item label="密码" prop="password">
                  <el-input type="password" autocomplete="current-password" v-model="form.password" show-password />
                </el-form-item>
                <el-form-item>
                  <base-row>
                    <el-button class="login-button" type="primary" @click="onSubmit('register-form-' + tab.name)">登录</el-button>
                    <el-button @click="onFindPassword">找回密码</el-button>
                  </base-row>
                </el-form-item>
                <el-form-item>
                  <base-row>
                    <el-button @click="onRegister">注册</el-button>
                  </base-row>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </base-row>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLogo from '@/components/BaseLogo'
import BaseSeparationRow from '@/components/BaseSeparationRow'
import api from '@/api'
import { dealOtherError } from '@/api/error'
import rule from '@/validate/rule'
import { APICode } from '@/api/common'

export default {
  name: 'Login',
  components: {
    BaseLogo,
    BaseSeparationRow
  },
  data () {
    return {
      tabs: [
        {
          label: '用户名',
          name: 'username'
        },
        {
          label: '邮箱',
          name: 'email'
        }
      ],
      form: {
        loginType: 'username',
        username: '',
        email: '',
        password: ''
      },
      rules: {
        email: rule.email,
        username: rule.username,
        password: rule.password
      },
      loading: false
    }
  },
  methods: {
    async login () {
      try {
        await api.login(this.form.loginType, this.loginID, this.form.password)
        if (this.$route.query.redirect) await this.$router.push(this.$route.query.redirect.toString())
        else await this.$router.push({ name: 'Home' })
      } catch (error) {
        this.loading = false
        if (error.resp) {
          const resp = error.resp
          if (resp.code === APICode.ValidateFailed) {
            this.$message.error('用户名或密码错误，请检查后重试')
            return
          }
        }
        dealOtherError(this, error)
      }
    },
    onSubmit (formName) {
      this.$refs[formName][0].validate((valid) => {
        if (!valid) {
          this.$message.error('格式错误！请检查数据格式')
          return false
        }
        this.loading = true
        this.login()
      })
    },
    onKeyDown (event) {
      if (event.key === 'Enter' && !this.loading) {
        this.onSubmit(this.getFormName)
      }
    },
    onFindPassword () {
      this.$router.push({ name: 'FindPassword' })
    },
    onRegister () {
      this.$router.push({ name: 'Register' })
    }
  },
  computed: {
    loginID () {
      if (this.form.loginType === 'username') return this.form.username
      else return this.form.email
    },
    getFormName () {
      return 'register-form-' + this.form.loginType
    }
  }
}
</script>

<style scoped>
.login-page {
  display: flex;
  justify-content: center;
  height: 100vh;
}

.login-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.login-block {
  padding: 20px 50px 20px 50px;
  box-shadow: rgba(0, 0, 0, .1) 0 2px 4px, rgba(0, 0, 0, .06) 0 1px 1px;
}

.el-button {
  flex-grow: 1;
}

.login-button {
  flex-grow: 10;
}
</style>
